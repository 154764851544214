import React, { useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import "../style/style.css"
import Flag from "react-flagpack"

const Index = () => {
  const [showInstall, setShowInstall] = useState(false)
  const [prompt, setPrompt] = useState(null)
  const appButtonRef = useRef(null)

  useEffect(() => {
    // Initialize deferredPrompt for use later to show browser install prompt.
    let deferredPrompt

    window.addEventListener("beforeinstallprompt", e => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      deferredPrompt = e
      setPrompt(e)
      // Update UI notify the user they can install the PWA
      setShowInstall(true)
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`)
    })

    appButtonRef.current.addEventListener("click", e => {
      console.log("button was clicked")
      if (prompt) {
        prompt.prompt()

        prompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt")
            setShowInstall(false)
          } else {
            console.log("User dismissed the A2HS prompt")
          }
          deferredPrompt = null
          setPrompt(null)
        })
      }
    })
  }, [showInstall])

  return (
    <div className="App">
      <div className="container homepage">
        <Header title="Pick a race" showSiteName={true} />
        <p>
          Having trouble distinguishing riders? Can't tell{" "}
          <span className="underline">Stybar</span> from{" "}
          <span className="underline">Lampaert</span> ? Not sure if it's{" "}
          <span className="underline">Peter</span> or{" "}
          <span className="underline">Juraj</span>?
        </p>

        <p>
          <span className="underline">Who's That Rider</span> has the solution!
          Simply enter a rider's number to find out who it is.{" "}
        </p>

        <Link to="/vuelta-2022" className="raceLink vuelta">
          <img alt="bikeIcon" src="/bike.png" />
          Vuelta a España 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"ES"} />
        </Link>

        <Link to="/past-races/" className="raceLink vuelta">
          <img alt="bikeIcon" src="/bike.png" />
          See all past races
        </Link>
        <div
          className="raceLink reloadHome"
          onClick={() => {
            window.location.reload()
          }}
        >
          <img alt="refresh icon" src="/bike.png" />
          Load latest races
        </div>
        <div
          className="raceLink reloadHome addApp"
          ref={appButtonRef}
          style={{ visibility: showInstall ? `initial` : `hidden` }}
        >
          <img alt="refresh icon" src={require("../images/add.png")} />
          Add to Home Screen
        </div>
        <p>
          <em>The perfect tool for cycling fans and (aging) commentators</em>
        </p>
        <p className="contact">
          <a href="mailto:korodotdev@gmail.com">Contact</a> —{" "}
          <a href="https://twitter.com/whosthatrider">Twitter</a> —{" "}
          <a href="https://koro.dev">Dev</a>
        </p>
      </div>
    </div>
  )
}

export default Index
